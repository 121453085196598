<template>
    <div class="container">
        <md-dialog :md-active.sync="showEdiAjustDest" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
            <b-row class="" v-if="infoEdit.cuenta == null">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.criterio}}</h5>
                    </b-col>
                    <b-col cols="3" lg="3" class="text-left">
                        <input type="text" v-model="critCuenta" v-on:input="buscaCuenta" placeholder="Criterio Cuenta..." class="form-control">
                    </b-col>
                    <b-col cols="5" lg="5" class="text-left">
                        <input type="text" v-model="critDescripcion" v-on:input="buscaDescripcion" placeholder="Criterio Descripcion..." class="form-control">
                    </b-col>
            </b-row>
                <b-row class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.cuenta}}</h5>
                    </b-col>
                    <b-col v-if="infoEdit.cuenta" cols="8" class="text-left">
                        <h6>{{ infoEdit.cuenta }}</h6>
                    </b-col>
                <b-col v-else cols="8" class="text-left">
                        <select multiple v-model="listadoCuentasDest" class="form-control">
                            <option v-for="(cuenta,idx) in listCuentasSelMul" :value="cuenta.cuenta" :key="idx+'-'+cuenta.id">{{ cuenta.cuenta }} {{ cuenta.descripcion }}</option>
                        </select>
                </b-col>
            </b-row>
                <b-row v-if="infoEdit.cuenta" class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.description}}</h5>
                    </b-col>
                    <b-col cols="8" class="text-left">
                        <h6>{{ infoEdit.descripcion }}</h6>
                    </b-col>
                </b-row>
                <b-row v-if="infoEdit.cuenta" class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.valor}}</h5>
                    </b-col>
                    <b-col cols="8" class="text-left">
                        <md-field>
                            <md-input v-model="infoEdit.valor"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row v-if="infoEdit.cuenta" class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.funcional}}</h5>
                    </b-col>
                    <b-col cols="8" class="text-left">
                        <md-field>
                            <md-input v-model="infoEdit.funcional"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row v-if="infoEdit.cuenta" class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.alterna}}</h5>
                    </b-col>
                    <b-col cols="8" class="text-left">
                        <md-field>
                            <md-input v-model="infoEdit.alterna"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="guardaAjuste">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
  
<script>
import AjustesConsol from '../../services/AjustesConsol'

export default {
    name:"EditAjustesDest",
    props:['showEdiAjustDest','infoEdit','listCuentas','listCuentasSelMul','codReporte','idCompania','vigencia','secuencia','tipo','tipo_ajuste'],
    data: () => ({
        critCuenta : "",
        critDescripcion : "",
	listadoCuentasDest : [],
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "Edita ajuste",
                    save : "Guardar",
                    close : "Cerrar",
                    criterio : "Criterio",
                    cuenta : "Cuenta",
                    description : "Descripción",
                    valor : "Valor",
                    funcional : "Funcional",
                    alterna : "Alterna"
                    
                }
            }else{
                return {
                    title : "Edit setting",
                    save : "Save",
                    close : "Close",
                    criterio : "Criterium",
                    cuenta : "Account",
                    description : "Description",
                    valor : "Value",
                    funcional : "Functional",
                    alterna : "Alternate"
                    
                }
            }
        }
    },
    methods:{
        buscaCuenta(){
            const criterio = "^"+this.critCuenta
            if	(this.critCuenta.length ==0) {
                this.listCuentasSelMul = this.listCuentas
            } else {
                this.listCuentasSelMul = this.listCuentas.filter(
                (cuenta) => cuenta.cuenta.match(criterio)>0)
            }
        },
        buscaDescripcion(){
            if	(this.critDescripcion.length == 0) {
                this.listCuentasSelMul = this.listCuentas
            } else {
                this.listCuentasSelMul = this.listCuentas.filter(
                (cuenta) => cuenta.descripcion.toUpperCase().indexOf(this.critDescripcion.toUpperCase())>=0)
            }
        },
        guardaAjuste(){

		if	(this.infoEdit.valor === '') {
			this.infoEdit.valor = null
		}
		if	(this.infoEdit.funcional === '') {
			this.infoEdit.funcional = null
		}
		if	(this.infoEdit.alterna === '') {
			this.infoEdit.alterna = null
		}
		if	(this.infoEdit.valor != null &&
			(this.infoEdit.funcional === null ||
			this.infoEdit.alterna === null)) {
			this.$toast.error("Funcional y Alterna no pueden ser nulos")
			return
		}

		if	(this.infoEdit.valor === null &&
			(this.infoEdit.funcional != null ||
			this.infoEdit.alterna != null)) {
			this.$toast.error("Funcional y Alterna deben ser nulos")
			return
		}

		let form = {}
		if	(this.infoEdit.cuenta) {
			form = {
				'id' :  this.infoEdit.id,
				'vigencia' :  this.infoEdit.vigencia,
				'companyId' :  this.infoEdit.id_compania,
				'valor' :  this.infoEdit.valor,
				'funcional' :  this.infoEdit.funcional,
				'alterna' :  this.infoEdit.alterna,
				'idUser' :  this.$store.state.userId,
			}
		} else {
			form = {
				'vigencia' :  this.vigencia,
				'secuencia' :  this.secuencia,
				'codReporte' : this.codReporte,
				'companyId' :  this.idCompania,
				'tipo'      : this.tipo,
				'listadoCuentas' : this.listadoCuentasDest,
				'idUser' :  this.$store.state.userId,
			}
		}

            AjustesConsol.updateAjusteDestino(form)
            .then((res) => {
                // console.log(res.data.message)
                if(res.status == 200){
                    this.$toast.success(res.data.message)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    this.reloadTbl()
                }, 2000)
            })

        },
        closeModal(){
            this.$emit('closeModal',false)
        },
        reloadTbl(){
            let form = {
                'id' :  this.secuencia,
                'vigencia' :  this.vigencia,
                'companyId' :  this.idCompania
            }
            this.$emit('reloadTblDest',form)
        }
    }
}
</script>

<style>

</style>
