<template>
  <div class="container">
    <div class="col-md-12"><h2>{{ labels.title }}</h2></div>
    <div class="row mt-5">
        <div class="col-md-3 h5">
            <select id="codReporte" name="" v-model="codReporte" v-on:click="f_actualiza_reporte()" >
                <option value="">{{labels.reporte}}...</option>
                <option v-for="reporte in reportes" :key="reporte.codigo" :value="reporte.codigo">{{reporte.codigo}}-{{reporte.nombre}}</option>
            </select>
        </div>
        <div class="col-md-3 h5">
            <select id="selVigencia" name="" v-model="selVigencia" v-on:click="f_actualiza_vigencia()">
                <option value="">{{labels.vigencia}}...</option>
                <option v-for="vigencia in listVigencia" :key="vigencia.vigencia"> {{ vigencia.vigencia }}</option>
            </select>
        </div>
        <div class="col-md-6 h5">
            <select id="company" name="" v-model="companyId" v-on:click="f_actualiza_compania()">
                <option value="">{{labels.company}}...</option>
                <option v-for="company in listCompany" :value="company.id" :key="company.id">{{ company.acronym+" "+company.nombre }}</option>
            </select>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <b-button @click="consultaNiveles()" id="consult-btn">{{ labels.consultar }}</b-button>
        </div>
    </div>
    <div class="row mt-3" v-if="showNiveles">
        <div class="col-md-3">
            <NivelUno @envLevUno="recNivUno"/>
        </div>
        <div class="col-md-3">
            <NivelDos @envLevDos="recNivDos" v-if="showDos == true" :infoNivDos="listLevDos" :nivelUnoSel="nivUnoSelected" />
        </div>
        <div class="col-md-3">
            <NivelTres @envLevTresNull="recNivTresNull"  @envLevTres="recNivTres" @abreNivelCuatro="abreNivelCuatro" v-if="showTres == true" :infoNivTres="listLevTres" :nivelDosSel="nivDosSelected" :nivelUnoSel="nivUnoSelected" />
        </div>
        <div class="col-md-3">
            <NivelCuatro @envLevCuatro="recNivCuatro" v-if="showCuatro == true" :infoNivCuatro="listLevCuatro" :nivelTresSel="nivTresSelected" :nivelDosSel="nivDosSelected" :nivelUnoSel="nivUnoSelected" />
        </div>
    </div>
    <div class="row mt-3">
        <NivelCuatroDetalle v-if="showCuatroDet == true" :codReporte="codReporte" :infoNivCuatroDetal="listLevCuatroDet" :infoNivelSelected="nivelSelect" :infoVigenCompa="dataVigenCompa"/>
    </div>
  </div>
</template>

<script>

import NivelUno from './NivelUno.vue'
import NivelDos from './NivelDos.vue'
import NivelTres from './NivelTres.vue'
import NivelCuatro from './NivelCuatro.vue'
import NivelCuatroDetalle from './NivelCuatroDetalle.vue'
import Nivel from '../../services/Nivel'
import PlanCuentas from "../../services/PlanCuentas"
import Company from "../../services/Company"
import Balance from "../../services/BalanceNiif"

export default {
    name:"Niveles",
    data: () => ({
	vigencia_anterior : '',
	compania_anterior : '',
        showDos: false,
        showTres: false,
        showCuatro: false,
        showCuatroDet: false,
        // showVigCompany: false,
        showNiveles: false,
        titleUno : "",
        titleDos : "",
        titleTres: "",
        levDosSel: "",
        listLevDos : [],
        listLevTres : [],
        listLevCuatro : [],
        listLevCuatroDet : [],
        selVigencia : "",
        codReporte : "",
        codReporteAnterior : "",
        companyId : "",
        listVigencia : [],
        listCompany : [],
        reportes : [],
        nivelSelect: {},
        dataVigenCompa: {},
        nivUnoSelected : "",
        nivDosSelected : "",
        nivTresSelected : "",
    }),
    components:{
        NivelUno,
        NivelDos,
        NivelTres,
        NivelCuatro,
        NivelCuatroDetalle
    },
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title: this.$route.params.tipo == 'directo' ? "NIVELES: FLUJO DE EFECTIVO-METODO DIRECTO" : "NIVELES: FLUJO DE EFECTIVO-METODO INDIRECTO",
                    vigencia: "Vigencia:",
                    company: "Compañía:",
                    reporte: "Reporte",
                    consultar: "Consultar"
                }
            }else{
                return {
                    title: this.$route.params.tipo == 'directo' ? "LEVELS: CASH FLOW STATEMENT-DIRECT METHOD" : "LEVELS: CASH FLOW STATEMENT-INDIRECT METHOD",
                    vigencia: "Validity:",
                    company: "Company:",
                    reporte: "Report",
                    consultar: "Consult"
                }
            }
        }
    },
    methods:{
        goToRoute(page){
            this.$router.push({path: page })
        },
	f_actualiza_compania() {
		if	(this.compania_anterior != this.companyId ) {
			this.showNiveles=false
			this.showDos=false
			this.showTres=false
			this.showCuatro=false
			this.showCuatroDet=false
			this.compania_anterior = this.companyId
		}
	},
	f_actualiza_reporte() {
		if	(this.codReporteAnterior != this.codReporte ) {
			this.showNiveles=false
			this.showDos=false
			this.showTres=false
			this.showCuatro=false
			this.showCuatroDet=false
			this.codReporteAnterior = this.codReporte
		}
	},
	f_actualiza_vigencia() {
		if	(this.vigencia_anterior != this.selVigencia ) {
			this.showNiveles=false
			this.showDos=false
			this.showTres=false
			this.showCuatro=false
			this.showCuatroDet=false
			this.vigencia_anterior = this.selVigencia
		}
	},
        consultaNiveles(){
            if(this.selVigencia != "" && this.companyId != ""){
                this.showNiveles = true

                this.dataVigenCompa = {
                    "vigencia" : this.selVigencia,
                    "compania" : this.companyId,
                    "codReporte" : this.codReporte,
                }
            
            }else{
                this.$toast.error("Diligenciar el campo vigencia y/o compañia")
            }
        },
        recNivUno(nivUno){
		this.titleUno = nivUno
		this.nivUnoSelected = nivUno
		this.nivDosSelected = ""
		this.nivTresSelected = ""
		this.showDos = false
		this.showTres = false
		this.showCuatro = false
		this.showCuatroDet = false
		this.listLevCuatroDet = []
		this.titleDos = ""
		this.titleTres = ""
		if	(nivUno != ""){
			this.showDos = true
			if(this.$route.params.tipo == 'directo'){
				Nivel.getLevTwoByLevOne(nivUno)
				.then((res) => {
					this.listLevDos = res.data.nivelDosEfe
				})
				.catch((err) => {
					console.log(err)
				})
			}else{
				Nivel.getLevTwoByLevOneInd(nivUno)
				.then((res) => {
					this.listLevDos = res.data.nivelDosEfe
				})
				.catch((err) => {
					console.log(err)
				})
			}
		}
        },

        recNivDos(nivDos){
// console.log(nivDos)
		this.titleDos = nivDos
		this.nivDosSelected = nivDos
		this.nivTresSelected = ""
		this.showTres = false
		this.showCuatro = false
		this.showCuatroDet = false
		this.titleTres = ""
		if	(nivDos != ""){
			this.showTres = true
			if(this.$route.params.tipo == 'directo'){
				Nivel.getLevThreeByLevOneLevTwo(this.titleUno,nivDos)
				.then((res) => {
					this.listLevTres = res.data.nivelTresEfe
				})
				.catch((err) => {
				console.log(err)
				})
			}else{
				Nivel.getLevThreeByLevOneLevTwoInd(this.titleUno,nivDos)
				.then((res) => {
					this.listLevTres = res.data.nivelTresEfe
				})
				.catch((err) => {
					console.log(err)
				})
			}

		}
        },

        recNivTresNull(nivTres){
            if(nivTres == null){
                this.showCuatro = true

                if(this.$route.params.tipo == 'directo'){

                    Nivel.getLevFourByLevOneLevTwoLevThreeNull(this.titleUno,this.titleDos,nivTres)
                    .then((res) => {
                        this.listLevCuatro = res.data.nivelCuatroEfe
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }else{

                    Nivel.getLevFourByLevOneLevTwoLevThreeNullInd(this.titleUno,this.titleDos,nivTres)
                    .then((res) => {
                        this.listLevCuatro = res.data.nivelCuatroEfe
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }
                
            }else{
                this.showCuatro = false
                this.showCuatroDet = false
            }
        },

        recNivTres(nivTres){
            this.titleTres = nivTres
            this.nivTresSelected = nivTres
            if(nivTres != ""){
                this.showCuatro = true

                if(this.$route.params.tipo == 'directo'){

                    Nivel.getLevFourByLevOneLevTwoLevThree(this.titleUno,this.titleDos,nivTres)
                    .then((res) => {
                        this.listLevCuatro = res.data.nivelCuatroEfe
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }else{
                    Nivel.getLevFourByLevOneLevTwoLevThreeInd(this.titleUno,this.titleDos,nivTres)
                    .then((res) => {
                        this.listLevCuatro = res.data.nivelCuatroEfe
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }

            }else{
                this.showCuatro = false
                this.showCuatroDet = false
            }
        },

        abreNivelCuatro(opcion){
		this.showCuatro = opcion
	},
        recNivCuatro(nivCuatro){
            this.nivelSelect = {
                "nivelUno" : this.titleUno, 
                "nivelDos" : this.titleDos, 
                "nivelTres" : this.titleTres, 
                "nivelCuatro" : nivCuatro
            }
            this.listLevCuatroDet = []
            if(nivCuatro != "" && this.titleTres != ""){
                this.showCuatroDet = true

                if(this.$route.params.tipo == 'directo'){

                    Nivel.getLevDetalFourByLevOneLevTwoLevThreeLevFour(this.titleUno,this.titleDos,this.titleTres,nivCuatro,this.selVigencia,this.companyId,this.codReporte)
                    .then((res) => {
                        this.listLevCuatroDet = res.data.nivCuatroEfeDet
                    })
                    .catch((err) => {
                        if(err.response.status == 404){
                            this.$toast.error("No hay cuentas para este nivel")
                        }
                    })
                }else{
                    Nivel.getLevDetalFourByLevOneLevTwoLevThreeLevFourInd(this.titleUno,this.titleDos,this.titleTres,nivCuatro,this.selVigencia,this.companyId,this.codReporte)
                    .then((res) => {
                        this.listLevCuatroDet = res.data.nivCuatroEfeDet
                    })
                    .catch((err) => {
                        if(err.response.status == 404){
                            this.$toast.error("No hay cuentas para este nivel")
                        }
                    })
                }
            
            }else{
                // this.showCuatroDet = true
                if(this.$route.params.tipo == 'directo'){

                    Nivel.getLevDetalFourByLevOneLevTwoLevThreeNullLevFour(this.titleUno,this.titleDos,null,nivCuatro,this.selVigencia,this.companyId,this.codReporte)
                    .then((res) => {
                        this.listLevCuatroDet = res.data.nivCuatroEfeDet
                        this.showCuatroDet = true
                    })
                    .catch((err) => {
                        if(err.response.status == 404){
                            this.$toast.error("No hay cuentas para este nivel")
                            this.showCuatroDet = true
                            // this.showVigCompany = true
                        }
                    })
                }else{
                    Nivel.getLevDetalFourByLevOneLevTwoLevThreeNullLevFourInd(this.titleUno,this.titleDos,null,nivCuatro,this.selVigencia,this.companyId,this.codReporte)
                    .then((res) => {
                        this.listLevCuatroDet = res.data.nivCuatroEfeDet
                        this.showCuatroDet = true
                    })
                    .catch((err) => {
                        if(err.response.status == 404){
                            this.$toast.error("No hay cuentas para este nivel")
                            this.showCuatroDet = true
                            // this.showVigCompany = true
                        }
                    })
                }

            }

        }
    },
    mounted(){

        PlanCuentas.getVigenciaPlancuentas()
        .then((result) => {
            this.listVigencia = result.data.getVigencia
        })
        .catch((err) => {
            console.log(err)
        })

        Company.getAllCompanies()
        .then((result) => {
            this.listCompany = result.data.companies
        })
        .catch((err) => {
            console.log(err)
        })
	Balance.getReportes()
	.then ((result) => {
		this.reportes = result.data.reportes.filter((reporte) => reporte.principal==1)
	})
	.catch(() => {
		this.error = true
	});
    },
    watch:{
        titleUno(valNew,valOld){
            if(valOld != ""){
                this.showTres = false
                this.showCuatro = false
                this.showCuatroDet = false

                this.titleDos = ""
                this.listLevDos = []
                this.titleTres = ""
                this.titleCuatro = ""
            }
        },

        titleDos(valNew,valOld){
            if(valOld != ""){
                // this.showTres = false
                this.showCuatro = false
                this.showCuatroDet = false

                // this.titleDos = ""
                this.listLevTres = []
                this.titleTres = ""
                this.titleCuatro = ""
            }
        },

        titleTres(valNew,valOld){
            if(valOld != ""){
                // this.showTres = false
                // this.showCuatro = false
                this.showCuatroDet = false

                this.listLevCuatro = []
                this.titleCuatro = ""
            }
        }
    }
}
</script>

<style src="../main.css">

</style>
