<template>
  <div class="container">
    <md-table v-model="ajustOrigen" md-card md-fixed-header>
        <md-table-toolbar>
            <h1 class="md-title"><b><i>{{labels.titleTbl}}
		</i></b></h1>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell :md-label="labels.cuenta" class="text-center">{{item.cuenta}}</md-table-cell>
            <md-table-cell :md-label="labels.descripcion" class="text-left" >{{ item.descripcion }}</md-table-cell>
            <md-table-cell :md-label="labels.valor" class="text-left" >{{ item.valor }}</md-table-cell>
            <md-table-cell :md-label="labels.funcional" class="text-left" >{{ item.funcional }}</md-table-cell>
            <md-table-cell :md-label="labels.alterna" class="text-left" >{{ item.alterna }}</md-table-cell>
            <md-table-cell :md-label="labels.edit" class="text-center" >
                <md-button @click="editAjuste(item.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
            </md-table-cell>
            <md-table-cell :md-label="labels.borrar" class="text-center" >
                <md-button @click="borraCuentasOrigen(item.id)" style="background-color: #cd2838; width: 35px; height: 35px;" class="md-fab md-primary" ><md-icon>delete</md-icon></md-button>
            </md-table-cell>
        </md-table-row>
    </md-table>
            <md-table-cell :md-label="labels.edit" class="text-center" >
                <md-button @click="adicionaCuentasOrigen()"  class = "md-raised md-primary btn-info"><md-icon>add</md-icon></md-button>
            </md-table-cell>
    <EditAjustesOrigenVue :showEdiAjustOrig="showEdiAjustOrig" :dataEdit="ajustes" :listCuentas="listCuentas" :listCuentasSelMul="listCuentasSelMul" :idCompania="id_compania" :codReporte="codReporte" :vigencia="vigencia" :secuencia="ajustOrigen[0].secuencia" :tipo="ajustOrigen[0].tipo" :tipo_ajuste="ajustOrigen[0].tipo_ajuste" @reloadTblOrig="recargaTabla" @closeModal="cierraModal"/>
  </div>
</template>
<script>

import EditAjustesOrigenVue from './EditAjustesOrigen.vue'
import AjustesConsol from '../../services/AjustesConsol'
import PlanCuentas from '../../services/PlanCuentas'

export default {
    name:"AjustesOrigen",
    props:['ajustOrigen','codReporte','id_compania','vigencia'],
    components:{EditAjustesOrigenVue},
    data: () => ({
        ajustes:[],
        showEdiAjustOrig:false,
	listCuentas: [],
	listCuentasSelMul: [],
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){

                return {
                    titleTbl : "Ajustes Origen",
                    cuenta : "Cuenta",
                    descripcion : "Descripción",
                    valor : "Valor",    
                    funcional : "Funcional",    
                    alterna : "Alterna",
                    edit : "Editar"    ,
                    borrar : "Borrar"    ,
                }
            }else{

                return {
                    titleTbl : "Origin Settings",
                    cuenta : "Cuenta",
                    descripcion : "Descripción",
                    valor : "Valor",
                    funcional : "Funcional",
                    alterna : "alterna",
                    edit : "Edit",
                    borrar : "Delete"    ,
                }
            }
        }
    },
    methods:{
        async editAjuste(id){
		this.ajustes = this.ajustOrigen.find((origen) => origen.id == id)
		this.showEdiAjustOrig = true
        },
        async borraCuentasOrigen(id){
            // console.log(id)
            const val = {
			id : this.ajustOrigen[0].secuencia,
			vigencia : this.vigencia,
			idCompany : this.id_compania,
		}
            await AjustesConsol.borraCuentasOrigen(id)

            this.recargaTabla(val)
        },
        async adicionaCuentasOrigen(){
                PlanCuentas.getCuentasPorCompaVigRepor(this.id_compania,this.vigencia,this.codReporte)
                .then((res) => {
                    this.listCuentas = res.data.planCuentas
                    this.listCuentasSelMul = res.data.planCuentas
                })
                .catch((err) =>{
                    console.log(err)
                })
		this.ajustes = [{id_compania: this.ajustOrigen[0].id_compania }]
		this.showEdiAjustOrig = true
        },
        cierraModal(val){
            this.showEdiAjustOrig = val
        },
        async recargaTabla(val){
            this.showEdiAjustOrig = false
            let ajusteOrig = await AjustesConsol.consulAjusteOrigen(val.id,val.vigencia,val.idCompany)
            this.ajustOrigen = ajusteOrig.data.ajustOrig
		console.log(ajusteOrig)
        }
    }

}
</script>
