<template>
    <div class="container">
        <md-dialog :md-active.sync="modEditaReporte" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.numero}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <!-- <md-input type="number" v-model="numero" class="form-control" /> -->
                        <h5>{{ ItemReport.codigo }}</h5>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.nombre}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <md-input type="text" v-model="ItemReport.nombre" class="form-control" />
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.principal}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <select v-model="ItemReport.principal" class="form-control">
                            <option disabled :value="ItemReport.principal">{{ ItemReport.principal == true ? "SI" : "NO"}}</option>
                            <option value="1">{{ labels.yes }}</option>
                            <option value="0">{{ labels.not }}</option>
                        </select>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="editaReporte">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import TipoReporte from '../../../services/TipoReporte'

export default {
    name:'EditaReporte',
    props:['modEditaReporte','ItemReport'],
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : 'Editar reporte',
                    save : 'Guardar',
                    close : 'Cerrar',
                    numero : 'Código:',
                    nombre : 'Nombre:',
                    principal : 'Principal:',
                    select : 'Seleccione...',
                    yes : 'SI',
                    not : 'NO'
                }
            }else{
                return {
                    title : 'Edit report',
                    save : 'Save',
                    close : 'Close',
                    numero : 'Code:',
                    nombre : 'Name:',
                    principal : 'Principal:',
                    select : 'Select...',
                    yes : 'YES',
                    not : 'NO'
                }
            }
        }
    },
    methods:{
        editaReporte(){
            console.log('edita',this.ItemReport.nombre,this.ItemReport.principal);
            if(this.ItemReport.nombre == ''){
                this.$toast.error('Campo(s) pendiente(s) por diligenciar');
            
            }else{
                console.log(this.ItemReport);
                this.ItemReport.userModifica = this.$store.state.userId
                TipoReporte.updateReporte(this.ItemReport)
                .then((res) => {
                    this.$toast.success(res.data.message)
                    this.$emit('refrescaTbl')
                })
                .catch((err) => {
                    console.log(err);
                    // this.$toast.error(err.response.data.message)
                })
            }
        },
        closeModal(){
            this.$emit('closeModal',false)
        }
    }
}
</script>

<style>

</style>
