<template>
    <div class="container" v-if="ajustDestino.length != 0">
        <md-table v-model="ajustDestino" md-card md-fixed-header>
            <md-table-toolbar>
                <h1 class="md-title"><b><i>{{labels.titleTbl}}</i></b></h1>
                <!-- <pre>
                    {{ ajustDestino }}
                </pre> -->
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell :md-label="labels.cuenta" class="text-center">{{item.cuenta}}</md-table-cell>
                <md-table-cell :md-label="labels.descripcion" class="text-left" >{{ item.descripcion }}</md-table-cell>
                <md-table-cell :md-label="labels.valor" class="text-left" >{{ item.valor }}</md-table-cell>
                <md-table-cell :md-label="labels.funcional" class="text-left" >{{ item.funcional }}</md-table-cell>
                <md-table-cell :md-label="labels.alterna" class="text-left" >{{ item.alterna }}</md-table-cell>
                <md-table-cell :md-label="labels.edit" class="text-center" >
                    <md-button @click="editAjuste(item.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
                </md-table-cell>
            <md-table-cell :md-label="labels.borrar" class="text-center" >
                <md-button @click="borraCuentasDestino(item.id)" style="background-color: #cd2838; width: 35px; height: 35px;" class="md-fab md-primary" ><md-icon>delete</md-icon></md-button>
            </md-table-cell>
            </md-table-row>
        </md-table>
            <md-table-cell :md-label="labels.edit" class="text-center" >
                <md-button @click="adicionaCuentasDestino()"  class = "md-raised md-primary btn-info"><md-icon>add</md-icon></md-button>
            </md-table-cell>
        <EditAjustesDest :showEdiAjustDest="showEdiAjustDest" :infoEdit="ajustes" :listCuentas="listCuentas" :listCuentasSelMul="listCuentasSelMul" :idCompania="id_compania" :codReporte="codReporte" :vigencia="vigencia" :secuencia="ajustDestino[0].secuencia" :tipo="ajustDestino[0].tipo" :tipo_ajuste="ajustDestino[0].tipo_ajuste" @reloadTblDest="recargaTabla" @closeModal="cierraModal" />
    </div>
</template>
<script>

import EditAjustesDest from './EditAjustesDest.vue';
import AjustesConsol from '../../services/AjustesConsol'
import PlanCuentas from '../../services/PlanCuentas'

export default {
    name:"AjustesDestino",
    props:['ajustDestino','codReporte','id_compania','vigencia'],
    components:{EditAjustesDest},
    data: () => ({
        ajustes:[],
        showEdiAjustDest:false,
	listCuentas: [],
	listCuentasSelMul: [],
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){

                return {
                    titleTbl : "Ajustes Destino",
                    cuenta : "Cuenta",
                    descripcion : "Descripción",
                    valor : "Valor",    
                    funcional : "Funcional",    
                    alterna : "Alterna",
                    edit : "Editar",
                    borrar : "Borrar",
                }
            }else{

                return {
                    titleTbl : "Destination Settings",
                    cuenta : "Cuenta",
                    descripcion : "Descripción",
                    valor : "Valor",
                    funcional : "Funcional",
                    alterna : "alterna",
                    edit : "Edit",
                    borrar : "Delete",
                }
            }
        }
    },
    methods:{
        cierraModal(val){
            this.showEdiAjustDest = val
        },
        async editAjuste(id){
            // console.log(id)
            let editAjuste = []
            editAjuste = await AjustesConsol.consulAjusteDestinoById(id)
            
            this.ajustes = editAjuste.data.ajuste[0]
            this.showEdiAjustDest = true
        },
        async borraCuentasDestino(id){
            // console.log(id)
            const val = {
			id : this.ajustDestino[0].secuencia,
			vigencia : this.vigencia,
			idCompany : this.id_compania,
		}
            await AjustesConsol.borraCuentasDestino(id)

            this.recargaTabla(val)
        },
        async adicionaCuentasDestino(){
                PlanCuentas.getCuentasPorCompaVigRepor(this.id_compania,this.vigencia,this.codReporte)
                .then((res) => {
                    this.listCuentas = res.data.planCuentas
                    this.listCuentasSelMul = res.data.planCuentas
                })
                .catch((err) =>{
                    console.log(err)
                })
		this.ajustes = [{id_compania: this.ajustDestino[0].id_compania }]
		this.showEdiAjustDest = true
        },
        async recargaTabla(val){
            // console.log(val)

            this.showEdiAjustDest = false
            let ajusteDestino = await AjustesConsol.consulAjusteDestino(val.id,val.vigencia)
            // console.log(ajusteDestino)
            this.ajustDestino = ajusteDestino.data.ajustDest
        }
    }

}
</script>
