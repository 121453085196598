<template>
  <div class="container">
    <md-dialog :md-active.sync="modalCreaEcp" :md-click-outside-to-close="false">
        <md-dialog-title class="text-center">
            {{ labels.newEcp }}
        </md-dialog-title>
        <md-dialog-content>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.actual}}</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <select v-model="actual" id="" class="form-control">
                        <option value=""> {{ labels.select }} </option>
                        <option value="1"> {{ labels.yes }} </option>
                        <option value="0"> {{ labels.not }} </option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.operacion}}</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <select v-model="operacion" id="" class="form-control">
                        <option value=""> {{ labels.select }} </option>
                        <option value="+"> {{ labels.sumar }} </option>
                        <option value="-"> {{ labels.restar }} </option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.contable}}</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <select v-model="contable" id="" class="form-control">
                        <option value=""> {{ labels.select }} </option>
                        <option value="1"> {{ labels.yes }} </option>
                        <option value="0"> {{ labels.not }} </option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="" v-if="showCuenta">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.criterio}}</h5>
                </b-col>
                <b-col cols="3" lg="3" class="text-left">
                    <input type="text" v-model="critCuenta" v-on:input="buscaCuenta" placeholder="Criterio Cuenta..." class="form-control">
                </b-col>
                <b-col cols="3" lg="3" class="text-left">
                    <input type="text" v-model="critDescripcion" v-on:input="buscaDescripcion" placeholder="Criterio Descripcion..." class="form-control">
                </b-col>
            </b-row>
            <b-row class="" v-if="showCuenta">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.account}}</h5>
                </b-col>
                <!-- <b-col cols="6" lg="6" class="text-left">
                    <md-field>
                        <md-input
                            type="text"
                            v-model="cuenta"                                
                        />
                    </md-field>
                </b-col> -->
                <b-col cols="6" lg="6" class="text-left">
                    <select multiple v-model="listadoCuentas" class="form-control">
                        <option v-for="cuenta in listCuentasSelMul" :value="cuenta.cuenta" :key="cuenta.id">{{ cuenta.cuenta }} {{ cuenta.descripcion }}</option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="" v-if="showFormulacion">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.formulacion}}</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <input type="radio" value="m" id="" v-model="chk" class="ml-3"> <span>Manual</span>
                    <input type="radio" value="sf" id="" v-model="chk" class="ml-3"> <span>Fila</span>
                    <input type="radio" value="sc" id="" v-model="chk" class="ml-3"> <span>Columna</span>
                    <input type="radio" value="c" id="" v-model="chk" class="ml-3"> <span>Celda</span>
                    <input type="radio" value="n" id="" v-model="chk" class="ml-3"> <span>Niif</span>
                </b-col>
            </b-row>
            <b-row class="" v-if="showSumaFila">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>Suma Fila:</h5>
                </b-col>
                <b-col cols="1" lg="6" class="text-left">
                    <md-field>
                        <!-- <label>Number</label> -->
                        <md-input v-model="sumFila" type="text"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <b-row class="" v-if="showSumaColumna">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>Suma Columna:</h5>
                </b-col>
                <b-col cols="1" lg="6" class="text-left">
                    <md-field>
                        <!-- <label>Number</label> -->
                        <md-input v-model="sumColumna" type="text"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <b-row class="" v-if="showCelda">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>Celda:</h5>
                </b-col>
                <b-col cols="1" lg="2" class="text-left">
                    <md-field>
                        <md-input v-model="celFila" type="number"></md-input>
                    </md-field>
                </b-col>
                <b-col cols="1" lg="2" class="text-left">
                    <md-field>
                        <md-input v-model="celColumna" type="number"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <b-row class="" v-if="showNiif">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>Niif:</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                            <select multiple v-model="arrNiif" class="form-control">
                                <option v-for="niif in listNiif" :value="niif.niif" :key="niif.id">{{niif.niif}}-{{ niif.nameNiif }}</option>
                            </select>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <md-dialog-actions class="mt-3">
                <md-button class="md-primary" @click="guardaCuenta">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import EcpReporte from '../../services/EcpReporte';
import PlanConsolidacion from '../../services/PlanConsolidacion';

export default {
    name:"NewCuentaEcp",
    props:['modalCreaEcp','vigComSelect','cuentasCreadas'],
    data: () => ({
        contable: "",
        cuenta: "",
        actual: "",
        operacion: "",
        showCuenta: false,
        showFormulacion: false,
        chk:"",
        showSumaFila: false,
        showSumaColumna: false,
        showNiif: false,
        listNiif: [],
        listLevel: [],
        arrNiif:[],
        sumFila: "",
        sumColumna: "",
        listadoCuentas : [],
        listCuentas : [],
        listCuentasSelMul : [],
        showCelda: false,
        celFila:"",
        celColumna:"",
        critCuenta:"",
        critDescripcion:"",
    }),
    computed: {
        labels(){
            if(this.$store.state._language == "es-ES"){

                return {
                    newEcp : "Nuevo detalle ECP",
                    select: "Seleccione...",
                    save : "Guardar",
                    close : "Cerrar",
                    contable : "Contable:",
                    yes : "SI",
                    not : "NO",
                    criterio : "Criterio:",
                    account : "Cuenta:",
                    actual : "Actual:",
                    operacion : "Operación:",
                    sumar : "+",
                    restar : "-",
                    formulacion : "Formulación:",
                }  
            }else{
                return {
                    newEcp : "New ECP detail",
                    select: "Select...",
                    save : "Save",
                    close : "Close",
                    contable : "Accountant:",
                    yes : "YES",
                    not : "NO",
                    criterio : "Criterion:",
                    account : "Account:",
                    actual : "Actual:",
                    operacion : "Operation:",
                    sumar : "+",
                    restar : "-",
                    formulacion : "Formulation:",
                }                
            }
        }
    },
    methods:{
        buscaCuenta(){
            const criterio = "^"+this.critCuenta
            if	(this.critCuenta.length ==0) {
                this.listCuentasSelMul = this.listCuentas
            } else {
                this.listCuentasSelMul = this.listCuentas.filter(
                (cuenta) => cuenta.cuenta.match(criterio)>0)
            }
        },
        buscaDescripcion(){
            if	(this.critDescripcion.length == 0) {
                this.listCuentasSelMul = this.listCuentas
            } else {
                this.listCuentasSelMul = this.listCuentas.filter(
                (cuenta) => cuenta.descripcion.toUpperCase().indexOf(this.critDescripcion.toUpperCase())>=0)
            }
        },
        guardaCuenta(){
            // console.log("SAVE")
            if	(this.chk == 'n'){
		if	(this.arrNiif.length == 0) {
			this.$toast.success("Niif sin seleccionar")
			return
		}
            }
            if(this.vigComSelect.vigencia == ""){
                this.$toast.error("El campo vigencia debe estar diligenciado")
            }else if(this.vigComSelect.companyId == ""){
                this.$toast.error("El campo compañía debe estar diligenciado")
            }else if(this.contable == ""){
                this.$toast.error("El campo contable debe estar diligenciado")
            }else if(this.actual == ""){
                this.$toast.error("El campo actual debe estar diligenciado")
            }else if(this.operacion == ""){
                this.$toast.error("El campo operación debe estar diligenciado")
            }else{

                let dataInfo = {
                    "vigencia"          :   this.vigComSelect.vigencia,
                    "companyId"         :   this.vigComSelect.companyId,
                    "codReporte"         :   this.vigComSelect.codReporte,
                    "fila"              :   this.vigComSelect.fila,
                    "columna"           :   this.vigComSelect.columna,
                    "contable"          :   this.contable,
                    "cuenta"            :   this.contable == 1 ? this.listadoCuentas : "",
                    "actual"            :   this.actual,
                    "operacion"         :   this.operacion,
                    "formulacion"       :   this.contable == 0 ? this.chk : "",
                    "formSumaFila"      :   this.chk == "sf" ? this.sumFila : "",
                    "formSumaColumna"   :   this.chk == "sc" ? this.sumColumna : "",
                    "formSumaCelda"     :   this.chk == "c" ? this.celFila+','+this.celColumna : "",
                    "formNiif"          :   this.chk == "n" ? this.arrNiif : "",
                    'idUser'            :   this.$store.state.userId
                }
                // console.log(dataInfo)

                EcpReporte.insertDetalleEcp(dataInfo)
                .then((resp) => {
                    // console.log(resp)
                    this.$toast.success(resp.data.message)
                    this.clearForm()
                })
                .catch((err) => {
                    console.log(err)
                    if(err.response.status == 412){
                        this.$toast.error(err.response.data.message)
                    }
                })
                .finally(() => {
                    setTimeout(() => {                        
                        this.reloadTable()
                    }, 3000);
                })
            }
        },
        clicked(){
            this.contable = ""
            this.actual = ""
            this.operacion = ""
            this.chk = ""
            this.showFormulacion = false,
            this.sumFila = ""
            this.sumColumna = ""
            this.arrNiif = []
            this.celFila = ""
            this.celColumna = ""
            this.critCuenta = ""
            this.critDescripcion
            
            this.$emit('cierraMod', false);
        },
        consCuentas(companyId,vigencia,codReporte){

            let form = {
                'companyId' : companyId,
                'vigencia' : vigencia,
                'codReporte' : codReporte,
                'actual' : this.actual,
                'operacion' : this.operacion,
                'fila' : this.vigComSelect.fila,
                'columna' : this.vigComSelect.columna,
                'contable' : this.contable,
                // 'cuentasList' : this.cuentasCreadas                
            }

            EcpReporte.cuentasNoCreadas(form)
            .then((result) => {
                this.listCuentas = result.data.cuentas
                this.listCuentasSelMul = result.data.cuentas
                this.showCuenta = true
            })
            .catch((err) => {
                console.log(err)
            })
        },
        reloadTable(){
            this.$emit('reloadTable',this.vigComSelect)
        },
        clearForm(){
            this.contable = ""
            this.listCuentas = []
            this.listadoCuentas = []
            this.actual = ""
            this.operacion = ""
            this.chk = ""
            this.sumFila = ""
            this.sumColumna = ""
            this.arrNiif = []
            this.celFila = ""
            this.celColumna = ""
            this.critCuenta = ""
            this.critDescripcion
        }
    },
    watch:{
        contable(valNew){
            if(valNew ==  0){
                // console.log("Show Formulacion")
                this.showCuenta = false
                this.showFormulacion = true
                this.listCuentas = []

            }else if(valNew == 1){
                this.showCuenta = true
                this.showFormulacion = false
                this.showSumaFila = false
                this.showNiif = false
                this.chk = ""
                this.listLevel = []            
                this.arrNiif = []
                this.consCuentas(this.vigComSelect.companyId,this.vigComSelect.vigencia,this.vigComSelect.codReporte)            
            }
        },
        actual(valNewAct,valOldAct){
            // console.log(valNewAct,valOldAct)
            if(valNewAct != valOldAct && this.contable == 1){
                this.showCuenta = false
                this.consCuentas(this.vigComSelect.companyId,this.vigComSelect.vigencia,this.vigComSelect.codReporte)
            }
        },
        operacion(valNewOpe,valOldOpe){
            // console.log(valNewOpe,valOldOpe)
            if(valNewOpe != valOldOpe && this.contable == 1){
                this.showCuenta = false
                this.consCuentas(this.vigComSelect.companyId,this.vigComSelect.vigencia,this.vigComSelect.codReporte)
            }
        },
        chk(valNew){
            // console.log(valNew)
            if(valNew == 'n'){
                // console.log('niif')
                if(this.vigComSelect.vigencia != ""){

		this.arrNiif=[]
		PlanConsolidacion.getPlanConsPorCompVige(this.vigComSelect.vigencia,this.vigComSelect.codReporte)
		.then((res) => {
			this.listNiif = res.data.planConsNiif
		})
		.catch((err) => {
			console.log(err)
		})
                    
                    this.showNiif = true
                    this.showSumaFila = false
                    this.showSumaColumna = false
                    this.showCelda = false
                    this.listLevel = []
                    this.sumFila = ""
                    this.sumColumna = ""
                    this.celFila = ""
                    this.celColumna = ""
                    
                }else{
                    this.$toast.error("El campo vigencia debe estar diligenciado")
                }

                // this.showNiif = true
                // this.showSumaFila = false
            }else if(valNew == 'sf'){
                // console.log('suma')
                this.showSumaFila = true
                this.showSumaColumna = false
                this.showCelda = false
                this.showNiif = false
                this.arrNiif = []
                this.sumColumna = ""
                this.sumFila = ""
                this.celFila = ""
                this.celColumna = ""
                
            }else if(valNew == 'sc'){
                this.showSumaColumna = true
                this.showSumaFila = false
                this.showCelda = false
                this.showNiif = false
                this.arrNiif = []
                this.celFila = ""
                this.celColumna = ""


            }else if(valNew == 'c'){
                this.showCelda = true
                this.showSumaFila = false
                this.showSumaColumna = false
                this.showNiif = false
                this.listLevel = []
                this.arrNiif = []
                this.sumFila = ""
                this.sumColumna = ""

            }else if(valNew == 'm'){
                // console.log('manual')
                this.showSumaFila = false
                this.showSumaColumna = false
                this.showNiif = false
                this.showCelda = false
                this.listLevel = []
                this.arrNiif = []
                this.sumFila = ""
                this.sumColumna = ""
                this.celFila = ""
                this.celColumna = ""
            }
        },
    }
}
</script>

<style>

</style>
