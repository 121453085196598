<template>
    <div class="container ">
      <md-table md-card v-model="detalleNivCuatro">
        <md-table-toolbar>
            <h1 class="md-title">{{ labels.detail }}</h1>
<!--            <md-button style="background-color: #e5ac00; width: 35px; height: 35px;" class="md-fab md-primary" @click="editaTodo">
                <md-icon>edit</md-icon>
            </md-button> -->
            <md-button style="background-color: #cd2838; width: 35px; height: 35px;" class="md-fab md-primary" @click="borraTodo">
                <md-icon>delete</md-icon>
            </md-button>
            <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="newCuenta">
                <md-icon>add</md-icon>
            </md-button>
        </md-table-toolbar>
        <!-- <md-table-row>
            <md-table-head>Contable</md-table-head>
            <md-table-head>Cuenta</md-table-head>
            <md-table-head>Actual</md-table-head>
            <md-table-head>Operacion</md-table-head>
            <md-table-head>Formulación</md-table-head>
        </md-table-row> -->
        <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell :md-label="labels.contable">{{ getContable(item.contable) }}</md-table-cell>
            <md-table-cell :md-label="labels.cuenta">{{ item.cuenta }}</md-table-cell>
            <md-table-cell :md-label="labels.actual">{{ getActual(item.actual) }}</md-table-cell>
            <md-table-cell :md-label="labels.operacion">{{ item.operacion }}</md-table-cell>
            <md-table-cell :md-label="labels.formulacion">{{ getFormulacion(item.formulacion, item.contable) }}</md-table-cell>
            <md-table-cell :md-label="labels.editar">
                <md-button style="background-color: #e5ac00; width: 35px; height: 35px;" class="md-fab md-primary" @click="editCuenta(item.id)">
                    <md-icon>edit</md-icon>
                </md-button>
            </md-table-cell>
            <md-table-cell :md-label="labels.eliminar">
                <md-button style="background-color: #cd2838; width: 35px; height: 35px;" class="md-fab md-primary" @click="deleteCuenta(item.id)">
                    <md-icon>delete</md-icon>
                </md-button>
            </md-table-cell>
        </md-table-row>
      </md-table>
      <CreaCuenta :modalCreaCuenta="modalCreaCuenta" @clicked="closeModal" :nivelSelected="infoNivelSelected" :vigComSelected="infoVigenCompa" :cuentasCreadas="listCuentExist" @reloadTable="reloadTable"/>
      <EditaCuenta :modalEditaCuenta="modalEditaCuenta" @clicked="closeModal" :dataDetallCuent="infoCuenta" @reloadTable="reloadTable"/>
      <ConfirmDialog :showConfirmDialog="showConfirmDialog" :borraUno="borraUno" @cancelDel="closeModal" @confirmDelete="confirmDelete" />
    </div>
  </template>
  
<script>

import CreaCuenta from './CreaCuenta.vue'
import EditaCuenta from './EditaCuenta.vue'
import Nivel from '../../services/Nivel'
import ConfirmDialog from './ConfirmDialog.vue'

export default {
    name:"NivelCuatroDetalle",
    components:{CreaCuenta,EditaCuenta,ConfirmDialog},
    data:() => ({
        detalleNivCuatro : [],
        modalCreaCuenta: false,
        modalEditaCuenta: false,
        showConfirmDialog: false,
        borraUno: true,
        regDel: "",
        infoCuenta: {},
        listCuentExist : [],
	dataForm : ''
    }),
    props:['infoNivCuatroDetal','infoNivelSelected','infoVigenCompa','codReporte'],
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    detail: "Detalle",
                    contable: "Contable",
                    cuenta : "Cuenta",
                    actual : "Actual",
                    operacion: "Operación",
                    formulacion: "Formulación",
                    editar: "Editar",
                    eliminar: "Eliminar",
                }
            }else{
                return {
                    detail: "Detail",
                    contable: "Accountant",
                    cuenta : "Account",
                    actual : "Actual",
                    operacion: "Operation",
                    formulacion: "Formulation",
                    editar: "Edit",
                    eliminar: "Delete",
                }
            }
        }
    },
    methods:{
        editCuenta(id){
            // console.log(id)
            this.modalEditaCuenta = true

            if(this.$route.params.tipo == 'directo'){
                Nivel.getCuentaNivelEfeById(id)
                .then((res) => {
                    // console.log(res.data.detalleCuenta)
                    this.infoCuenta = res.data.detalleCuenta
                })
                .catch((err) => {
                    console.log(err)
                })
            }else{
                // console.log("INDIRECTO")
                Nivel.getCuentaNivelEfeByIdInd(id)
                .then((res) => {
                    // console.log(res.data.detalleCuenta)
                    this.infoCuenta = res.data.detalleCuenta
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        },
        deleteCuenta(id){
            // console.log(id)
            this.regDel = id
            this.showConfirmDialog = true
            this.borraUno = true
        },
        confirmDelete(){
		if	(!this.borraUno) {
			this.dataForm = this.infoNivelSelected
			this.dataForm.id_compania = this.infoVigenCompa.compania
			this.dataForm.vigencia = this.infoVigenCompa.vigencia
			this.dataForm.codReporte = this.codReporte
			if(this.$route.params.tipo == 'directo'){
				Nivel.deleteCuentaNivelEfeAll(this.dataForm)
				.then((res) => {
					// console.log(res)
					if(res.status == 200){
						this.$toast.success("Todos los registros Eliminados.")
						this.showConfirmDialog = false
						setTimeout(() => {

							this.reloadTable(this.infoNivelSelected,this.infoVigenCompa.vigencia,this.infoVigenCompa.compania,this.codReporte)
						},5000)
					}
				})
				.catch((err) => {
					console.log(err)
				})
			}else{
				// console.log('indirecto',this.regDel)
				Nivel.deleteCuentaNivelEfeIndAll(this.dataForm)
				.then((res) => {
					// console.log(res)
					if(res.status == 200){
						this.$toast.success("Todos los registros Eliminados.")
						this.showConfirmDialog = false
						setTimeout(() => {
							this.reloadTable(this.infoNivelSelected,this.infoVigenCompa.vigencia,this.infoVigenCompa.compania,this.codReporte)
						},5000)
					}
				})
				.catch((err) => {
					console.log(err)
				})
			}
		} else {
			// console.log(this.regDel)
			if(this.$route.params.tipo == 'directo'){
				Nivel.deleteCuentaNivelEfe(this.regDel)
				.then((res) => {
					// console.log(res)
					if(res.status == 200){
						this.$toast.success("Se ha eliminado el registro.")
						this.showConfirmDialog = false
						this.reloadTable(this.infoNivelSelected,this.infoVigenCompa.vigencia,this.infoVigenCompa.compania,this.codReporte)
					}
				})
				.catch((err) => {
					console.log(err)
				})
			}else{
				// console.log('indirecto',this.regDel)
				Nivel.deleteCuentaNivelEfeInd(this.regDel)
				.then((res) => {
					// console.log(res)
					if(res.status == 200){
						this.$toast.success("Se ha eliminado el registro.")
						this.showConfirmDialog = false
						this.reloadTable(this.infoNivelSelected,this.infoVigenCompa.vigencia,this.infoVigenCompa.compania,this.codReporte)
					}
				})
				.catch((err) => {
					console.log(err)
				})
			}
		}
        },
        getContable(valContable){
            return valContable == 1 ? 'SI' : 'NO'
        },
        getActual(valActual){
            return valActual == 1 ? 'SI' : 'NO'
        },
        getFormulacion(valFormulacion, valContable){
            
            if(valContable == 1){
                return ""
            }else{
                return valFormulacion
            }
        },
        newCuenta(){
            // console.log("ACA")
            this.modalCreaCuenta = true;
        },
        closeModal(val){
            this.modalCreaCuenta = val;
            this.modalEditaCuenta = val;
            this.showConfirmDialog = val;
        },
        reloadTable(nivelSelect,vigencia,compania,codReporte){
            // console.log(nivelSelect,vigencia,compania)
            if(nivelSelect.nivelTres != '' && nivelSelect.nivelTres != null){
                
                if(this.$route.params.tipo == 'directo'){
                    Nivel.getLevDetalFourByLevOneLevTwoLevThreeLevFour(nivelSelect.nivelUno,nivelSelect.nivelDos,nivelSelect.nivelTres,nivelSelect.nivelCuatro,vigencia,compania,codReporte)
                    .then((res) => {
                        // console.log(res.data.nivCuatroEfeDet)
                        // console.log(res)
                        this.detalleNivCuatro = res.data.nivCuatroEfeDet
                    })
                    .catch((err) => {
                        // console.log(err)
                        if(err.response.status == 404){
                            this.$toast.error("No hay cuentas para este nivel")
                            this.detalleNivCuatro = [] // Borra el arreglo para que no muesytre nada
                        }
                    })
                    this.modalCreaCuenta = false//cierra modal
                }else{
                    Nivel.getLevDetalFourByLevOneLevTwoLevThreeLevFourInd(nivelSelect.nivelUno,nivelSelect.nivelDos,nivelSelect.nivelTres,nivelSelect.nivelCuatro,vigencia,compania,codReporte)
                    .then((res) => {
                        // console.log(res.data.nivCuatroEfeDet)
                        // console.log(res)
                        this.detalleNivCuatro = res.data.nivCuatroEfeDet
                    })
                    .catch((err) => {
                        // console.log(err)
                        if(err.response.status == 404){
                            this.$toast.error("No hay cuentas para este nivel")
                            this.detalleNivCuatro = [] // Borra el arreglo para que no muesytre nada
                        }
                    })
                    this.modalCreaCuenta = false//cierra modal
                }

            }else{

                if(this.$route.params.tipo == 'directo'){
                    Nivel.getLevDetalFourByLevOneLevTwoLevThreeNullLevFour(nivelSelect.nivelUno,nivelSelect.nivelDos,null,nivelSelect.nivelCuatro,vigencia,compania,codReporte)
                    .then((res) => {
                        // console.log(res.data.nivCuatroEfeDet)
                        // console.log(res)
                        this.detalleNivCuatro = res.data.nivCuatroEfeDet
                    })
                    .catch((err) => {
                        // console.log(err)
                        if(err.response.status == 404){
                            this.$toast.error("No hay cuentas para este nivel")
                            this.detalleNivCuatro = [] // Borra el arreglo para que no muesytre nada
                        }
                    })
                    this.modalCreaCuenta = false//cierra modal
                }else{
                    Nivel.getLevDetalFourByLevOneLevTwoLevThreeNullLevFourInd(nivelSelect.nivelUno,nivelSelect.nivelDos,null,nivelSelect.nivelCuatro,vigencia,compania,codReporte)
                    .then((res) => {
                        // console.log(res.data.nivCuatroEfeDet)
                        // console.log(res)
                        this.detalleNivCuatro = res.data.nivCuatroEfeDet
                    })
                    .catch((err) => {
                        // console.log(err)
                        if(err.response.status == 404){
                            this.$toast.error("No hay cuentas para este nivel")
                            this.detalleNivCuatro = [] // Borra el arreglo para que no muesytre nada
                        }
                    })
                    this.modalCreaCuenta = false//cierra modal
                }
            }
        },
	borraTodo() {
		this.showConfirmDialog=true;
		this.borraUno = false
	},
	editaTodo() {
	}
    },
    mounted(){
        this.detalleNivCuatro = this.infoNivCuatroDetal
        // console.log(this.detalleNivCuatro)
        // console.log(this.infoNivCuatroDetal)
    },
    watch:{
        infoNivCuatroDetal(newVal){
            // console.log(newVal,oldVal)
            this.detalleNivCuatro = newVal

            this.detalleNivCuatro.forEach((elem) => {
                // console.log(elem.cuenta)
                if(elem.cuenta != null){
                    this.listCuentExist.push(elem.cuenta)
                }
            })
            // console.log(this.listCuentExist)
        }
    }
}
</script>
<style src="../main.css">
