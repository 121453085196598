<template>
    <div class="container">
        <md-dialog :md-active.sync="modalEditaEcp" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.editEcp }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.contable}}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <h6>{{ dataDetallEcp[0].contable == 1 ? 'SI' : 'NO' }}</h6>
                    </b-col>
                </b-row>
                <b-row class="" v-if="this.dataDetallEcp[0].contable == 1 ? true : showCuenta">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.account}}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <h6>{{ dataDetallEcp[0].cuenta }}</h6>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.actual}}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <select v-model="dataDetallEcp[0].actual" id="" class="form-control">
                            <option value="1"> {{ labels.yes }} </option>
                            <option value="0"> {{ labels.not }} </option>
                        </select>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.operacion}}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <select v-model="dataDetallEcp[0].operacion" id="" class="form-control">
                            <!-- <option value=""> {{ labels.select }} </option> -->
                            <option value="+"> {{ labels.sumar }} </option>
                            <option value="-"> {{ labels.restar }} </option>
                        </select>
                    </b-col>
                </b-row>
                <b-row class="" v-if="this.dataDetallEcp[0].contable == 0 ? true : showFormulacion">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.formulacion}}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <input type="radio" value="m" id="" v-model="chk" class="ml-3"> <span>Manual</span>
                        <input type="radio" value="sf" id="" v-model="chk" class="ml-3"> <span>Fila</span>
                        <input type="radio" value="sc" id="" v-model="chk" class="ml-3"> <span>Columna</span>
                        <input type="radio" value="c" id="" v-model="chk" class="ml-3"> <span>Celda</span>
                        <input type="radio" value="n" id="" v-model="chk" class="ml-3"> <span>Niif</span>
                    </b-col>
                </b-row>
                <b-row class="" v-if="showSumaFila">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>Suma Fila:</h5>
                    </b-col>
                    <b-col cols="1" lg="6" class="text-left">
                        <md-field>
                            <!-- <label>Number</label> -->
                            <md-input v-model="dataDetallEcp[0].formulacion" type="text"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="" v-if="showSumaColumna">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>Suma Columna:</h5>
                    </b-col>
                    <b-col cols="1" lg="6" class="text-left">
                        <md-field>
                            <!-- <label>Number</label> -->
                            <md-input v-model="dataDetallEcp[0].formulacion" type="text"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="" v-if="showCelda">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>Celda:</h5>
                    </b-col>
                    <b-col cols="1" lg="2" class="text-left">
                        <md-field>
                            <md-input v-model="regFila" type="number"></md-input>
                        </md-field>
                    </b-col>
                    <b-col cols="1" lg="2" class="text-left">
                        <md-field>
                            <md-input v-model="regCol" type="number"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="" v-if="showNiif">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>Niif:</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item">
                                <select multiple v-model="arrNiif" class="form-control">
                                    <option v-for="niif in listNiif" :value="niif.niif" :key="niif.id">{{niif.niif}}-{{ niif.nameNiif }}</option>
                                </select>
                            </div>
                        </div>
                    </b-col>
                </b-row>

                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="editaCuenta">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
  
<script>

import PlanConsolidacion from '../../services/PlanConsolidacion';
import EcpReporte from '../../services/EcpReporte';

export default {
    name:"EditaCuentaEcp",
    props:['modalEditaEcp','dataDetallEcp'],
    data: () => ({
        showCuenta : false,
        showFormulacion : false,
        chk : '',
        showNiif : false,
        showSumaFila : false,
        showSumaColumna : false,
        showCelda : false,
        regFila : "",
        regCol : "",
        listNiif : [],
        arrNiif : [],
    }),
    computed: {
        labels(){
            if(this.$store.state._language == "es-ES"){

                return {
                    editEcp : "Edita detalle ECP",
                    save : "Guardar",
                    close : "Cerrar",
                    contable : "Contable:",
                    yes : "SI",
                    not : "NO",
                    account : "Cuenta:",
                    actual : "Actual:",
                    operacion : "Operación:",
                    sumar : "+",
                    restar : "-",
                    formulacion : "Formulación:",
                    vigencia : "Vigencia:",
                    company : "Compañía:"
                }  
            }else{
                return {
                    editEcp : "Edit ECP detail",
                    save : "Save",
                    close : "Close",
                    contable : "Accountant:",
                    yes : "YES",
                    not : "NO",
                    account : "Account:",
                    actual : "Actual:",
                    operacion : "Operation:",
                    sumar : "+",
                    restar : "-",
                    formulacion : "Formulation:",
                    vigencia : "Validity:",
                    company : "Company:"
                }                
            }
        }
    },
    methods:{
        async editaCuenta(){

            if	(this.chk == 'n'){
		if	(this.arrNiif.length == 0) {
			this.$toast.success("Niif sin seleccionar")
			return
		}
            }
            this.dataDetallEcp[0].chkSel = this.chk
            if(this.chk == 'm'){
                this.dataDetallEcp[0].formulacion = "MANUAL"
            }else if(this.chk == 'n'){
                this.dataDetallEcp[0].formulacion = this.arrNiif
            
            }else if(this.chk == 'sf'){
                this.dataDetallEcp[0].formulacion
            
            }else if(this.chk == 'sc'){
                this.dataDetallEcp[0].formulacion
            
            }else if(this.chk == 'c'){
                this.dataDetallEcp[0].formulacion = 'CELDA('+this.regFila+','+this.regCol+')'
            }
            // console.log(this.dataDetallEcp[0])

            this.dataDetallEcp[0].idUser = this.$store.state.userId

            let updatDetEcp = await EcpReporte.updateDetCuentaEcp(this.dataDetallEcp[0])
            // console.log(updatDetEcp)
            if(updatDetEcp.status == 200){
                this.$toast.success(updatDetEcp.data.message)
                setTimeout(() => {                        
                    this.reloadTable()
                }, 1500);
            }else{
                this.$toast.error(updatDetEcp.data.message)
            }

        },
        clicked(){
            this.$emit('cierraMod', false);
        },
        asignaCelda(celda){
            let filaCel = celda.indexOf('(')
            let fila = celda.slice(filaCel+1,8)
            // console.log(fila) 
            this.regFila = fila

            let columnaCel = celda.indexOf(',')
            let columna = celda.slice(columnaCel+1,11)
            // console.log(columna)
            this.regCol = columna
        },
        reloadTable(){

            let info = {
                'vigencia' : this.dataDetallEcp[0].vigencia,
                'companyId' : this.dataDetallEcp[0].id_compania,
                'codReporte' : this.dataDetallEcp[0].codReporte,
                'fila' : this.dataDetallEcp[0].fila,
                'columna' : this.dataDetallEcp[0].columna
            }
            this.$emit('reloadTable',info)
        }
    },
    watch:{
        dataDetallEcp(valNew){
            // console.log(valNew)
            if(valNew[0].formulacion.slice(0,1) == "M"){
                this.chk = 'm'
                this.showNiif = false
                this.showSumaFila = false
                this.showSumaColumna = false
                this.showCelda = false
            
            }else if(valNew[0].formulacion.slice(0,1) == ""){
                this.showNiif = false
                this.showSumaFila = false
                this.showSumaColumna = false
                this.showCelda = false
            
            }else if(valNew[0].formulacion.slice(0,1) == "N" && valNew[0].contable == 0){
                this.chk = 'n'
                this.showNiif = true
                this.showSumaFila = false
                this.showSumaColumna = false
                this.showCelda = false

		this.arrNiif=[]
		PlanConsolidacion.getPlanConsPorCompVige(valNew[0].vigencia,this.codReporte)
		.then((res) => {
			this.listNiif = res.data.planConsNiif
		})
		.catch((err) => {
			console.log(err)
		})
            
            }else if(valNew[0].formulacion.slice(0,5) == "SUMAF" && valNew[0].contable == 0){
                this.chk = 'sf'
                this.showSumaFila = true
                this.showSumaColumna = false
                this.showCelda = false
                this.showNiif = false
            
            }else if(valNew[0].formulacion.slice(0,5) == "SUMAC" && valNew[0].contable == 0){
                this.chk = 'sc'
                this.showSumaColumna = true
                this.showSumaFila = false
                this.showCelda = false
                this.showNiif = false
            
            }else if(valNew[0].formulacion.slice(0,1) == "C" && valNew[0].contable == 0){
                this.chk = 'c'
                this.showCelda = true
                this.showSumaColumna = false
                this.showSumaFila = false
                this.showNiif = false
            }
        },
        chk(valNew){
            // console.log(valNew)
            // console.log(this.dataDetallEcp[0].formulacion)
            if(valNew == "m"){
                this.dataDetallEcp[0].formulacion = ""
                this.showNiif = false
                this.showSumaFila = false
                this.showSumaColumna = false
                this.showCelda = false
                this.dataDetallEcp[0].chkSel = valNew

            }else if(valNew == "n"){
                this.dataDetallEcp[0].formulacion = ""
                this.showNiif = true
                this.showSumaFila = false
                this.showSumaColumna = false
                this.showCelda = false
                this.dataDetallEcp[0].chkSel = valNew

		this.arrNiif=[]
		PlanConsolidacion.getPlanConsPorCompVige(this.dataDetallEcp[0].vigencia,this.dataDetallEcp[0].codReporte)
		.then((res) => {
			this.listNiif = res.data.planConsNiif
		})
		.catch((err) => {
			console.log(err)
		})

            }else if(valNew == "sf"){
                // this.dataDetallEcp[0].formulacion = ""
                this.showSumaFila = true
                this.showSumaColumna = false
                this.showCelda = false
                this.showNiif = false
                this.dataDetallEcp[0].chkSel = valNew
                this.dataDetallEcp[0].formulacion = ""
            
            }else if(valNew == "sc"){
                // this.dataDetallEcp[0].formulacion = ""
                this.showSumaColumna = true
                this.showNiif = false
                this.showSumaFila = false
                this.showCelda = false
                this.dataDetallEcp[0].chkSel = valNew
                this.dataDetallEcp[0].formulacion = ""
            
            }else if(valNew == "c"){                
                this.showCelda = true
                this.showSumaColumna = false
                this.showNiif = false
                this.showSumaFila = false
                this.dataDetallEcp[0].chkSel = valNew
                this.asignaCelda(this.dataDetallEcp[0].formulacion)
                this.dataDetallEcp[0].formulacion = ""
            }
        }
    }
}
</script>

<style>

</style>
