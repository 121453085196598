<template>
    <div class="container">
        <div class="col-md-12">
            <h3><b><i>{{ f_titulo() }}</i></b></h3>
        </div>
        <h4>
                <label for="selVigencia" class=""> {{ labels.vigencia }}</label>&nbsp;&nbsp; &nbsp;  
                <input type="month" name="" id="" v-model="selVigencia"><br> 
                <label for="company" class=""> {{ labels.companiaOrigen }}</label>&nbsp; &nbsp; &nbsp; 
                <select id="company" name="" v-model="companiaOrigen">
                    <option value="">{{labels.seleccione}}</option>
                    <option v-for="company in listCompany" :value="company.id" :key="company.id">{{ company.acronym+ " "+company.nombre }}</option>
                </select><br>
                <label for="company" class=""> {{ labels.companiaDestino }}</label>&nbsp; &nbsp; &nbsp;
                <select id="company" name="" v-model="companiaDestino">
                    <option value="">{{labels.seleccione}}</option>
                    <option v-for="company in listCompany" :value="company.id" :key="company.id">{{ company.acronym+ " "+company.nombre }}</option>
                </select><br>
		<span v-if="selVigencia && companiaOrigen&&companiaDestino ">
                      <input id="archivoExcel" type="file" @change="cargaExcel()" class="btn-crear">&nbsp; 
                      <span v-if="muestraHoja">
                            <label for="hoja" class=""> {{ labels.hoja }}</label>&nbsp; 
                            <select id="hoja" name="" v-model="selectedHoja" v-on:click="f_showHoja()">
                                <option value="">{{labels.seleccione}}</option>
                                <option v-for="(item,idx) in listHojas" :value="item" :key="idx">{{ item }}</option>
                            </select>
                      </span>
		</span>
        </h4>
        <div class="col-md-1"></div>
        <div v-if="showBtnHoja == true" class="col-md-12 row justify-content-center mt-4">
            <b-button @click="cargaInterco()" id="consult-btn">{{ labels.chargeHoja }}</b-button>
        </div>
        <div v-if="!isLoading"></div>
        <div class="loadingSpinner" :class="{ active: isLoading }">
            <b-spinner></b-spinner>
        </div>
    </div>
</template>
<script>

import Company from "../../services/Company"
import Cargue from "../../services/Cargue";
import * as XLSX from "xlsx"

export default{
name: "CargueBalance",
data: () => ({
	listCompany: [],
	listVigencia: [],
	companiaOrigen: "",
	companiaDestino: "",
	selVigencia: "",
	listCuentaOrigen: [],
	listHojas: [],
	showBtnHoja: false,
	isLoading: false,
	inputArch: "",
	workbook: "",
	muestraHoja: false,
	selHoja: false,
	selectedHoja: '',
	fileReader: '',
	formato: '',
}),
computed: {
            labels(){
                if(this.$store.state._language == "es-ES"){
                    return {
                        title : "CARGUE BALANCE FORMATO EXCEL SIN INFORMACION NIIF",
                        companiaOrigen : "Compañía Origen:",
                        companiaDestino : "Compañía Destino:",
                        vigencia : "Vigencia:",
                        charge : "Cargar",
                        chargeHoja : "Cargar Hoja",
			seleccione: "Seleccione.....",
			hoja: "Hoja"
                    }
                
                }else{
                    return {
                        title : "LOAD BALANCE SHEET EXCEL FORMAT WITHOUT NIIF INFORMATION",

                        company : "Company:",
                        companiaOrigen : "Origin Company:",
                        companiaDestino : "Destination Company:",
                        charge : "Charge",
                        chargeHoja : "Charge Sheet",
			seleccione: "Select.....",
			hoja: "Sheet"


                    }
                }
            }
},
methods: {
	f_titulo() {
		let titulo = ""
		let tituloEN=""
		titulo = "PROCESO DE CARGUE DE SALDOS INTERCOMPAÑIAS"
		tituloEN = "INTERCOMPANIES BALANCES LOADING PROCESS"
		if      (this.$store.state._language != "es-ES") {
			titulo= tituloEN
		}
		return titulo
	},
	cargaExcel() {
		this.showBtnHoja = false
		this.muestraHoja = false
		this.selHoja = false
		this.selectedHoja = false
                const inputArch = document.getElementById("archivoExcel")
		var selectedFile=inputArch.files[0]
		this.fileReader = new FileReader();
		this.fileReader.onload = function(event) {
			var data = event.target.result
			this.workbook = XLSX.read(data, {type: "binary"})
			let hojas = this.workbook.SheetNames
			this.listHojas=hojas
		};
		if	(selectedFile) {
			this.fileReader.readAsBinaryString(selectedFile)
			setTimeout(()=>{
					this.muestraHoja=true
					this.listHojas = this.fileReader.listHojas
					},2000)
		}
	},
	cargaInterco() {
		let rowObject = XLSX.utils.sheet_to_row_object_array(
			this.fileReader.workbook.Sheets[this.selectedHoja])
		this.listCuentaOrigen = []
		let cuentaOrigen=-1
		let saldoOrigen=-1
		rowObject.forEach(fila =>{
			var columnas=Object.values(fila)
			if	(cuentaOrigen >= 0) {
				const v_cuentaOrigen=Number.parseInt(columnas[cuentaOrigen])
				const v_saldoOrigen=Number.parseInt(columnas[saldoOrigen])
				console.log(v_cuentaOrigen,v_saldoOrigen)
				if	(Number.parseInt(columnas[cuentaOrigen])) {
						const descripcion =cuentaOrigen+1
						console.log(columnas[cuentaOrigen],columnas[descripcion],columnas[saldoOrigen])
				}
			} else {
				columnas.forEach((columna,idx) => {
					if	(columnas[idx] == "CUENTA" && cuentaOrigen <0) {
						cuentaOrigen = idx
					}
					if	(columnas[idx] == "SALDO ACTUAL" && saldoOrigen <0) {
						saldoOrigen = idx
					}
				})
			}
			this.listCuentaOrigen.push(columnas)
		})
/*		let form = {
			"vigencia"		: this.cambFormatoVigencia(),
			"companiaOrigen"	: this.companiaOrigen,
			"lisCuentaOrigen"	: this.listCuentaOrigen,
			"companiaDestino"	: this.companiaDestino,
			"lisCuentaDestino"	: this.listCuentaDestino,
			'idUser'		: this.$store.state.userId
		}
		let v_error = true
		Cargue.cargaInterco(form)
		.then((res) => {
			if	(res.status !== 200) {
				v_error = false
			} else {
				v_error = true
			}
		})
		if	(v_error) {
			this.$toast.error("Problema al Cargar")
		} else {
			setTimeout(()=>{
			this.actualizaNiif(form.vigencia,form.company)
			},10000)
		}
		if	(!v_error) {
			this.showBtnHoja = false
		}
*/
	},
	cambFormatoVigencia(){
                let anio = this.selVigencia.substring(0,4)
                let mes = this.selVigencia.substring(5,7)
                let newMes = mes

                return anio+newMes
	},
	f_showHoja() {
		this.showBtnHoja  = true
	},
},
mounted(){
	this.isLoading = true;

	Company.getAllFormatos()
	.then((result) => {
		this.listCompany = result.data.formatos
	})
	.catch((err) => {
		console.log(err)
	})
	.finally(() => {
		this.isLoading = false;
	})

	Cargue.getVigenciaCargue()
	.then((res) => {
		this.listVigencia = res.data.getVigencia
	})
	.catch((err) => {
		console.log(err)
	})
	.finally(() => {
		this.isLoading = false;
	})
	}
}
</script>
<style src="../main.css"></style>
